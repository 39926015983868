import React from 'react'
import { Select, MenuItem, Avatar, FormControlLabel, Switch, Typography, InputLabel } from '@mui/material'
import { DiscordGuildInput, MyDiscordGuild } from '@regolithco/common'
import { Diversity3 } from '@mui/icons-material'
import { Box, Stack } from '@mui/system'

export interface DiscordServerControlProps {
  lockToDiscordGuild?: DiscordGuildInput
  options: MyDiscordGuild[]
  isDiscordEnabled: boolean
  onChange: (method?: DiscordGuildInput) => void
}

export const DiscordServerControl: React.FC<DiscordServerControlProps> = ({
  lockToDiscordGuild,
  options,
  isDiscordEnabled,
  onChange,
}) => {
  const hasOneValid = options.length > 0 && options.some((guild) => guild.hasPermission)
  return (
    <Stack gap={2} direction="column">
      <FormControlLabel
        checked={Boolean(lockToDiscordGuild)}
        disabled={!isDiscordEnabled || !hasOneValid}
        control={
          <Switch
            onChange={(e) => {
              // If checked, find the first guild that has permission and submit that
              if (e.target.checked) {
                const myGuild = options.find((option) => option.hasPermission)
                if (myGuild) {
                  const { hasPermission, ...myGuildWithoutPermission } = myGuild
                  onChange(myGuildWithoutPermission)
                }
              } else {
                onChange(undefined)
              }
            }}
          />
        }
        label="Users must be a member of a discord server to join this session."
      />
      {!isDiscordEnabled && (
        <Typography variant="caption" color="text.secondary">
          You must be logged into Regolith using Discord authentication to use this option.
        </Typography>
      )}
      {isDiscordEnabled && !hasOneValid && (
        <Typography variant="caption" color="text.secondary">
          To use this option you must have permission to join a voice channel on at least one discord server.
        </Typography>
      )}
      {isDiscordEnabled && lockToDiscordGuild && <InputLabel id="label">Discord Server</InputLabel>}
      {isDiscordEnabled && lockToDiscordGuild && (
        <Select
          labelId="refineryMethod"
          id="refineryMethod"
          variant="standard"
          size="small"
          value={lockToDiscordGuild.id}
          label="Discord Server"
          fullWidth
          renderValue={(selected) => {
            const selectedOption = options.find((option) => option.id === selected)
            return (
              <Box display="flex" alignItems="center">
                <Avatar src={selectedOption?.iconUrl || undefined} sx={{ width: 24, height: 24, marginRight: 1 }}>
                  <Diversity3 color="primary" />
                </Avatar>
                {selectedOption?.name}
              </Box>
            )
          }}
          onChange={(event) => {
            const chosenGuild = options.find((option) => option.id === event.target.value)
            if (chosenGuild) {
              const { hasPermission, ...chosenGuildWithoutPermission } = chosenGuild
              onChange(chosenGuildWithoutPermission)
            }
          }}
        >
          {options.map((option) => (
            // I want an option with the guild name and icon and a suffix that says (can post) if the permissions are correct
            <MenuItem key={option.id} value={option.id} disabled={!option.hasPermission}>
              <Avatar src={option.iconUrl || undefined} sx={{ width: 24, height: 24, marginRight: 1 }}>
                <Diversity3 color="primary" />
              </Avatar>
              {option.name}
              {option.hasPermission ? '' : ' (missing permissions)'}
            </MenuItem>
          ))}
        </Select>
      )}
    </Stack>
  )
}
